import styled from 'styled-components';

const NewsWrapper = styled.div`
  padding: 40px 0;
  img {
    margin: 0 auto;
    padding: 0 px;
  }
  .misc_contianer {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
    border-bottom-width: 3px;
    border-bottom-color: #0057a9;
    border-bottom-style: solid;
  }
  .misc_contianer:hover {
    box-shadow: 0 16px 64px 0 rgba(18, 21, 26, 0.24);
  }
  .news_image {
    transform: translateY(0px);
    transition: all 0.5s ease;
  }
  .extender {
    display: block;
    width: 50px;
    height: 3px;
    background-color: #b6bfce;
    transition: all 0.5s ease;
    border-radius: 5px;
  }
  .individual_item:hover {
    div {
      .news_image {
        transform: translateY(-10px);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
          0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
          0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
      }
      .extender {
        width: 100px;
        background-color: #073363;
      }
    }
  }
`;

export default NewsWrapper;
